import React, {Fragment} from "react";
import styles from './Cross.module.css';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CellElement from "./CrossCell";
import DefElement from "./DefElement";
import AnswerElement from "./AnswerElement";
import i18n from "../i18n";
import {Translation} from "react-i18next";

function withHooks(WrappedComponent) {
    return function(props) {
        const location = useLocation();
        const nav = useNavigate();
        const { thema } = useParams();
        return <WrappedComponent location={location} nav={nav} {...props}/>
    };
}

class CrossGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rem_cell: [],
            answer: false,
            counter: 0,
            thema: '',
            lang: props.location.state.lang,
            user: JSON.parse(localStorage.getItem('spr_name')) || props.location.state.name
        };
        i18n.changeLanguage(props.location.state.lang);
    }

    getCrossword = () => {
       // console.log(this.state.rem_cell);
        return this.state.rem_cell.map(
            (el, key, liter, color, id, begin, cross_word_number, answer=this.state.answer) =>
                <CellElement elementData={el} key={key} liter={liter} color={color} id={id}
                             begin={begin} cross_word_number={cross_word_number} answer={answer}/>);
    }

    getDefinitions = () => {
        const words_for_definition = this.state.rem_cell.filter((word) =>
            word.begin === true
        );
        console.log(words_for_definition);
        return words_for_definition.map((el, i,liter, word_color, id, cross_word_number, answer=this.state.answer) =>
            <DefElement elementData={el} key={i} liter={liter} color={word_color} id={id} cross_word_number={cross_word_number} answer={answer} />);
    }

    showAnswers = () => {
        //console.log(this.state.rem_cell);
        this.setState(state => ({answer: true}))
       // this.componentDidMount()
    }

    logOut = () => {
        this.props.nav('/');
    }

    getWards = () => {
        console.log(this.state.words)
        return 1
    }

    getAnswers(json){
        let arr = []
        for(let i = 0; i < json.length; i++) {
            let obj = json[i];
            arr.push(obj);
        }
        //this.state.answers = arr;
        this.setState(state => ({words: arr}))
        //this.setState(state => ({repeat_value: this.state.repeat_value+1}))
    }

    componentDidMount() {
       // this.setState(state => ({rem_cell: CROSS_WORDS}))
       //console.log(this.props.location.state.thema)

        fetch('https://api.roomab.art/'+ this.props.location.state.thema)
            .then(response => response.json())
            .then(data => {
                //this.getAnswers(data)
                console.log(data)
                this.setState(state =>({rem_cell: data}))
                localStorage.setItem('rem_cell', JSON.stringify(data));
            });
    }

    render() {
    return (
        <Fragment>
            <h1>Hello, {this.state.user}</h1>
            <div className={`${styles.container} ${styles.sticky}`}>
                <div className={styles.crossword}>
                    <div className={styles.row}>
                        {this.getCrossword()}
                    </div>
                </div>
            </div>
            <div className={styles.definitions}>
                {this.getDefinitions()}
            </div>
            <div className="d-grid gap-2">
                <button type="button"
                        className={this.state.answer ? 'hidden' : 'btn btn-secondary btn-lg btn-block'}
                        onClick={this.showAnswers}>showAnswers
                </button>
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) =>
                            <button type="button" className="btn btn-secondary btn-lg btn-block" onClick={this.logOut}>
                                {t('logout')}
                            </button>
                    }
                </Translation>
            </div>
        </Fragment>
    );
    }
}

export default withHooks(CrossGrid)