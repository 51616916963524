import React, {Fragment} from "react";
import styles from './Cross.module.css';
import ReactHtmlParser from "react-html-parser";

class DefElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            word_id: '',
            definition: '',
            example: '',
            color: '',
            answer: this.props.answer
        };
        this.getDefinitionById(this.props.elementData.id);
    }

    getDefinitionById(id) {
        console.log(id);
        fetch('https://api.roomab.art/?id='+id)
            .then(response => response.json())
            .then(data => {
                let rand_definition= this.getRandomArrayIndex(data.definitions);
                console.log(data.definitions);
                this.setState(state =>({
                    id: data.id,
                    lang: data.lang,
                    definition: data.definitions[rand_definition],
                    example: (data.definitions[rand_definition].examples[0] ? data.definitions[rand_definition].examples[0].text : ''),
                    type: data.definitions[rand_definition].type
                }))

            });
        //console.log(this.state.definitions)
    }

    getRandomArrayIndex(array) {
        let array_max_index = array.length-1;
        return Math.floor(Math.random() * array_max_index);
    }

    getType(type_code, lang){
        if(lang==="1" && type_code===4) return ' <i>(auf Deutsch)</i>';
        if(lang==="0" && type_code===4) return ' <i>(in English)</i>';
        if(lang==="2" && type_code===3) return ' <i>(по-русски)</i>';
        if(lang==="1" && type_code===3) return ' <i>(по-русски)</i>';
    }


    render() {
        return (
            <Fragment>
                <div style={{color: this.props.elementData.word_color}} className={styles.invert}>
                    {this.props.elementData.cross_word_number} {ReactHtmlParser(this.state.definition.value)}
                    {ReactHtmlParser(this.getType(this.state.definition.type, this.state.lang))}
                </div>
                <div style={{color: this.props.elementData.word_color}}
                     className={this.props.answer ? `${styles.show} ${styles.invert} ${styles.example}`: 'hidden' }
                >
                    {ReactHtmlParser(this.state.example)}
                </div>
            </Fragment>
        );
    }
}


export default DefElement;